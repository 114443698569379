import React from 'react';

const Contact = () => {
  return (
    <div className="col">
      <h3>Contact</h3>
      <h5>Reach me in case you need me ;)</h5>
      <hr />

      <ul className="list-group">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <i className="fa fa-envelope-o" /> satyamsaxena0786@live.com
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <i className="fa fa-whatsapp" /> +31621205829
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <i className="fa fa-skype" /> satyam.limraweb
        </li>
      </ul>
    </div>
  );
};

export default Contact;
