import React from 'react';

const Footer = () => {
  return (
    <div className="row">
      <div className="col fixed-bottom bg-light">
        <ul className="footer-links">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/satyamsaxena1/"
            >
              <i className="fa fa-linkedin"> </i>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/satyamsaxena0786"
            >
              <i className="fa fa-facebook"> </i>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/satyamsaxena786"
            >
              <i className="fa fa-twitter"> </i>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/globetrotter91"
            >
              <i className="fa fa-github"> </i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
