import React from 'react';

const Work = () => {
  const workHistoryArray = [
    {
      title: 'Principal Futurologist',
      company: 'The Weird Science B.V.',
      from: 'Nov, 2017',
      to: 'Present',
      place: 'Utrecht, Netherlands',
      desc1: `The Weird Science team solves challenges in software engineering for businesses. We innovate, design and build solutions; and we lead, coach and mentor teams of dedicated professionals.`,
      desc2: `Working as a senior front-end engineer with focus on javascript and frameworks`,
      desc3: ''
    },
    {
      title: 'CTO',
      company: 'Origolo.com GmbH',
      from: 'Oct, 2016',
      to: 'Sept, 2016',
      place: 'Berlin, Germany',
      desc1: `Origolo is secure cloud storage platform and a communication platform with a added feature of zero-knowledge security. The data is encrypted/decrypted client side, reducing any chances of data theft by middle man attacks or server compromise.`,
      desc2: `Leading three different teams of developers to complete the mobile apps, desktop apps and other clients' projects respectively. Training the app development teams about the usage of the algorithms and APIS for successful integration.`,
      desc3: `Developed the current web-portal using AngularJs, NodeJs, ExpressJs, MongoDB from scratch. Created a custom file navigator component that can be used for any cloud application. Developed custom angular dependencies, modules for implementing a security algorithm for a client side encryption. Wrote the unit tests for the frontend and created the documentation for the backend APIs .`
    },
    {
      title: 'Co-Founder & CTO',
      company: 'Octonius Inc.',
      from: 'Mar, 2016',
      to: 'Oct, 2016',
      place: 'Barcelona, Spain',
      desc1: `Octonius is a simple, fast and safe cloud collaboration platform to share/sync files with anyone, using only your preferred cloud storage. Octonius also provides a slack application which helps teams in seamless file sharing across various cloud storage.`,
      desc2: `Led a small team of developers/designers to complete different projects from other clients in order to increase revenue. Responsible for upgrading and maintaining the product with help of A/B testing tools.`,
      desc3: `Integrated APIs for Dropbox, One drive, Box, Google Drive. Created custom directives to make the UI components reusable. Created a slack application for teams to receive file update notifications instantly and communicate flawlessly. Also responsible for server administration.`
    },
    {
      title: 'Co-Founder & CTO',
      company: 'FastOx',
      from: 'May, 2013',
      to: 'Feb, 2016',
      place: 'New Delhi, India',
      desc1: `Fastox was a on-demand hyper local delivery service that enabled consumer to book carriers for sending and tracking their packages in real-time within a city. Initially Fastox was a e-commerce portal that allowed users to order food and groceries.`,
      desc2: `Responsible for identifying issues using data from A/B testing and prioritising the tasks in order to deliver product of time. Led four 5-membered tech teams to complete different products in very short span of time to meet the growth of the company. Being a co-founder, also responsible for managing company operations from time to time, handling over 200 employees.`,
      desc3: `Developed the food-ordering portal using Zend Framework, HTML5 , Javascript. Responsible for creating self learning automated system with custom algorithms for route optimisation and backend APIs. Created the in office admin portal using NodeJs, MySQL, AngularJS for monitoring and management of the field executives in real-time.`
    },
    {
      title: 'Software Developer',
      company: 'Majestic IT Services Ltd.',
      from: 'Jun, 2012',
      to: 'Jan, 2013',
      place: 'New Delhi, India',
      desc1: `Majestic IT Service Ltd. Also known as Zatse was a search engine that used to find service providers near you depending on the service. It used a radial search algorithm to find the results.`,
      desc2: `Developed the user module of the website using Zend Framework, HTML CSS and JavaScript.`,
      desc3: `Did some search engine optimization for the search detail page of the website which helped increase the google page rank.`
    }
  ];

  const workHistory = workHistoryArray.map((workEntry, idx) => {
    return (
      <div className="card border-primary mb-3" key={idx}>
        <div className="card-body">
          <h4 className="card-title">{workEntry.title}</h4>
          <h5> {workEntry.company}</h5>
          <h6>
            {workEntry.from} - {workEntry.to} | {workEntry.place}{' '}
          </h6>
          <p className="card-text">
            {workEntry.desc1}
            <br />
            {workEntry.desc2}
            <br />
            {workEntry.desc3}
          </p>
        </div>
      </div>
    );
  });

  return (
    <div className="col">
      <h3>Work History</h3>
      <h5>Professional Experience</h5>
      <hr />
      {workHistory}
    </div>
  );
};

export default Work;
