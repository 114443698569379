import React from 'react';
// import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="col">
      <h5>Project Manager | Software Developer</h5>
      <hr />
      <div className="row">
        <div className="mb-sm-3 mb-xs-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <img
            className="image img-fluid"
            src="images/satyam_saxena_portrait.jpg"
            alt=""
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <p className="main-text">
            I do freelance software development and consulting. I am a full
            stack web developer. Implementing new features, refactoring old
            systems, building entire projects and MVPs from scratch.
            <br />
            I have a strong entrepreneurial spirit. I co-founded two companies
            and worked as a CTO, hired and built tech teams, managed projects
            and teams.
            <br />I am always open to learn new technologies. I am very excited
            about the future of JavaScript, TypeScript and Progressive Web app
            features. I love to travel, watch football and build robots.
          </p>
        </div>
      </div>

      <hr />
      {/* <Link to="/projects" className="btn btn-primary">
        Projects
      </Link> */}
      {/* <a
        rel="noopener noreferrer"
        target="_blank"
        href="https://interactive-cv.satyamsaxena.com"
        className="btn btn-primary pull-right"
      >
        <i className="fa fa-globe" /> Resume
      </a> */}
    </div>
  );
};

export default Home;
