import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
      <div className="row">
        <div className="col fixed-top bg-light">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 page-header">
                <ul className="nav nav-tabs pull-right">
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      <i className="fa fa-home" />{' '}
                      <span className="hidden-sm">Home</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/work" className="nav-link">
                      <i className="fa fa-briefcase" />{' '}
                      <span className="hidden-sm">Work</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/projects" className="nav-link">
                      <i className="fa fa-folder" />{' '}
                      <span className="hidden-sm">Projects</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                      <i className="fa fa-envelope" />{' '}
                      <span className="hidden-sm">Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 page-header">
                <h2>Satyam Saxena</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
