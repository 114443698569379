import React from 'react';

const Projects = () => {
  const projectsArray = [
    // {
    //   title: 'Tyamshi Coin',
    //   skills: 'C++, Cryptonote',
    //   desc: 'A cryptocurrency using the cryptonote blockchain.',
    //   githubLink: 'https://github.com/globetrotter91/tyamshicoin',
    //   webLink: ''
    // },
    {
      title: 'Water Battle Game',
      skills: 'THREE.js, NodeJS, Socket.io, ES6, HTML5',
      desc:
        'A multi player game of shooting ships build using THREE.js shaders and components',
      githubLink: 'https://github.com/globetrotter91/water-battle',
      webLink: ''
    },
    {
      title: 'F1 Application',
      skills: 'React, HTML, AngularJS, Ergast-API',
      desc:
        'A simple application to show the F1 seasons list along with champions between 2005 to 2015. Developed in React and Angular separately.',
      githubLink: 'https://github.com/globetrotter91/react-f1app',
      githubLink2: 'https://github.com/globetrotter91/ng-f1app',
      webLink: ''
    },
    {
      title: "Football Fan's Friend",
      skills: 'Angular4, RxJS, NodeJS, IndexedDB, Service Workers',
      desc: `This app uses the api.football-data.org API to show the show the fixtures and standings of the leagues selected by the user. The user can select one or more leagues and then further chose to see fixtures/standing of a particular league.`,
      githubLink: 'https://github.com/globetrotter91/fff-pwa',
      webLink: ''
    },
    {
      title: 'Kill The Enemy Game',
      skills: 'HTML5, Javascript, NodeJS, REDIS, Socket.io, OO-Design',
      desc:
        'Kill the enemy is a multi player game where players try to kill other players and our scored on that.',
      githubLink:
        'https://github.com/globetrotter91/multiplayer-game-html5-js-es6',
      webLink: ''
    },
    {
      title: 'Origolo',
      skills: 'AngularJS, NodeJs, MongoDB, Cryptography, Ansible',
      desc:
        'Origolo is secure cloud storage platform and a communication platform with a added feature of zero-knowledge security.',
      githubLink: '',
      webLink: 'https://origolo.com'
    },
    {
      title: 'Ad Agency Order Management Systems',
      skills: 'Angular, NodeJs, MongoDB',
      desc:
        'This is a lead management and marketing system that is fulfilling the needs of many small automobile businesses in United States. It helps agencies to monitor the track of their marketing campaigns they run for their dealers and also keeps their data safe at the same time',
      githubLink: '',
      webLink: 'http://agencyoms.com'
    }
  ];

  const projects = projectsArray.map((project, idx) => {
    return (
      <div className="card border-primary mb-3" key={idx}>
        <div className="card-body">
          <h4 className="card-title">{project.title}</h4>
          <h6 className="card-subtitle mb-2 text-muted">{project.skills}</h6>
          <p className="card-text">{project.desc}</p>
          {project.githubLink && (
            <a href={project.githubLink} className="card-link">
              <i className="fa fa-github" />
            </a>
          )}
          {project.githubLink2 && (
            <a href={project.githubLink2} className="card-link">
              <i className="fa fa-github" />
            </a>
          )}
          {project.webLink && (
            <a href={project.webLink} className=" pull-right card-link">
              <i className="fa fa-globe" />
            </a>
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="col">
      <h3>Projects</h3>
      <h5>What's going on ;)</h5>
      <hr />
      {projects}
    </div>
  );
};

export default Projects;
